const PhotographyData = [
  { image: "./img1.jpg" },
  { image: "./img3.jpg" },
  { image: "./img5.jpg" },
  { image: "./img6.jpg" },
  { image: "./img7.jpg" },
  { image: "./img9.jpg" },
  { image: "./img10.jpg" },
  { image: "./img12.jpg" },
  { image: "./img13.jpg" },
];

export default PhotographyData;
